import React from 'react'
import Button from 'components/Button'
import Table from 'components/Table'
import dayjs from 'dayjs'
import { useHistory } from 'react-router'

function List({
  headers,
  data,
  onEdit,
  title,
  buttons,
  onDelete,
  onReset,
  selectedData = [],
  onSelect,
  filter,
  loading,
  total,
}) {
  const router = useHistory()
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      filter={filter}
      buttons={buttons}
      total={total}
      rows={data?.map((e, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td
            onClick={() => {
              router.push('/member-detail/' + e.code)
            }}
            style={{ textDecoration: 'underline', color: 'blue' }}
          >
            {e.code}
          </td>
          <td>{e.name}</td>
          <td>{e.email}</td>
          {e.parentCode && (
            <td>
              Added by{' '}
              <span
                style={{ textDecoration: 'underline', color: 'blue' }}
                onClick={() => {
                  router.push('/member-detail/' + e.parentCode)
                }}
              >
                {e.parentCode}
              </span>
            </td>
          )}
          {!e.parentCode && <td>Added By Admin</td>}
          <td>{dayjs(e.created_at).format('DD/MM/YYYY')}</td>
          <td>
            <Button size="sm" color="info" onClick={() => onReset(e)}>
              Reset
            </Button>
            <Button size="sm" color="warning" onClick={() => onEdit(e)}>
              Edit
            </Button>
            <Button size="sm" color="danger" onClick={() => onDelete(e)}>
              Delete
            </Button>
          </td>
        </tr>
      ))}
    />
  )
}

export default List
