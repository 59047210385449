import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import queryString from 'query-string'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useMember from 'hooks/useMember'

function Member() {
  const router = useHistory()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const [showReset, setShowReset] = React.useState(false)
  const query = queryString.parse(router.location.search)
  let url = ''
  if (query.page) url += `?page=${query.page}`
  if (query.limit) url += `&limit=${query.limit}`

  const headers = ['No', 'Code', 'Name', 'Email', 'Added By', '']

  const { state, fetchMember, deleteMember, resetPasswordMember } = useMember()

  React.useEffect(() => {
    fetchMember(url)
  }, [url])

  return (
    <>
      <List
        headers={headers}
        total={state.total}
        data={state.members}
        title="Member"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        onReset={(data) => {
          setEditData(data)
          setShowReset(true)
        }}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                router.push('member-create')
              }}
            >
              Add New
            </Button>
            <Button
              onClick={() => {
                fetchMember(url)
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          fetchMember(url)
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Member`}
        body={`Are you sure to delete ${editData?.name}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false)
          deleteMember(editData.id)
          setTimeout(() => {
            fetchMember(url)
          }, 1000)
        }}
      />
      <ConfirmDialog
        modalOpen={showReset}
        title={`Reset Password`}
        body={`Are you sure to reset password of ${editData?.name}?`}
        onToggle={() => setShowReset(false)}
        onConfirm={() => {
          setShowReset(false)
          resetPasswordMember(editData.id)
          setTimeout(() => {
            fetchMember(url)
          }, 1000)
        }}
      />
    </>
  )
}

export default Member
