import React, { useState, useEffect } from 'react'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import usePlanSetting from 'hooks/usePlanSetting'

function AddFee({ level }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { id } = useParams()

  const [levels, setLevels] = useState([])
  const [mounted, setMounted] = useState(false)

  const { state, fetchPlanSetting, storePlanSetting } = usePlanSetting()

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchPlanSetting(id)
    }
  }, [mounted, fetchPlanSetting, id])

  useEffect(() => {
    let statesOfLevel = []

    if (state.planSettings.length > 0) {
      state.planSettings.map((planSetting) =>
        statesOfLevel.push({
          planId: planSetting.planId,
          level: planSetting.level,
          activityFee: planSetting.activityFee,
          noActivityFee: planSetting.noActivityFee,
        })
      )
    } else {
      Array(level)
        .fill(0)
        .forEach((_, index) => {
          statesOfLevel.push({
            planId: id,
            level: index + 1,
            activityFee: 0,
            noActivityFee: 0,
          })
        })
    }

    setLevels(statesOfLevel)
  }, [level, state.planSettings, id])

  const submitData = async () => {
    reset()
    await storePlanSetting(id, { planSettings: levels })
  }

  return (
    <Row>
      <Form onSubmit={handleSubmit(submitData)} className="col">
        <div className="pt-2 pb-2">
          {levels.map((level, index) => (
            <Row key={index}>
              <Col md="3 d-flex justify-content-center">
                <h3>{`Level ${level.level}`}</h3>
              </Col>

              <Col md="3 p-0">
                <FormGroup className="d-flex">
                  <label className="col-4">Activity Fee</label>
                  <div>
                    <input
                      type="number"
                      step={'any'}
                      className="form-control"
                      {...register(`activityFee${index}`, {
                        required: 'Activity Fee is required',
                      })}
                      errors={errors[`activityFee${index}`]}
                      autoComplete={'off'}
                      value={level.activityFee}
                      onChange={(event) => {
                        const newState = levels.map((obj) => {
                          if (obj === level) {
                            return {
                              ...obj,
                              activityFee: event.target.value,
                            }
                          }
                          return obj
                        })
                        setLevels(newState)
                      }}
                    />
                    {errors && (
                      <div
                        className="error-containe"
                        style={{
                          marginTop: 5,
                        }}
                      >
                        <span className="text-danger">
                          {errors[`activityFee${index}`]?.message}
                        </span>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col md="4 p-0">
                <FormGroup className="d-flex">
                  <label className="col-6 d-flex justify-content-end">
                    No Activity Fee
                  </label>

                  <div>
                    <input
                      type="number"
                      className="form-control"
                      {...register(`noActivityFee${index}`, {
                        required: 'No Activity Fee is required',
                      })}
                      errors={errors[`noActivityFee${index}`]}
                      autoComplete={'off'}
                      value={level.noActivityFee}
                      onChange={(event) => {
                        const newState = levels.map((obj) => {
                          if (obj === level) {
                            return {
                              ...obj,
                              noActivityFee: event.target.value,
                            }
                          }
                          return obj
                        })
                        setLevels(newState)
                      }}
                    />
                    {errors && (
                      <div
                        className="error-containe"
                        style={{
                          marginTop: 5,
                        }}
                      >
                        <span className="text-danger">
                          {errors[`noActivityFee${index}`]?.message}
                        </span>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          ))}

          <div className="mt-5 text-center">
            <Button>Save</Button>
          </div>
        </div>
      </Form>
    </Row>
  )
}

export default AddFee
