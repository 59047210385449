import React from 'react'
import { getAll, increase, decrease } from 'services/historyService'

const initialState = { histories: [], loading: false, total: 0 }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_HISTORY':
      return {
        ...state,
        histories: action.payload.data.histories,
        total: action.payload.data.total,
      }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useWalletHistory = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchHistory = async (id, query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(id, query)
    dispatch({ type: 'SET_HISTORY', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const increaseHistory = async (id, data) => {
    await increase(id, data)
  }

  const decreaseHistory = async (id, data) => {
    await decrease(id, data)
  }

  return {
    fetchHistory,
    increaseHistory,
    decreaseHistory,
    state: state,
  }
}

export default useWalletHistory
