import React from 'react'
import { Card, CardBody } from 'reactstrap'
import dayjs from 'dayjs'
import { useHistory } from 'react-router'
import Table from 'components/Table'
function DirectMembers({ data }) {
  const router = useHistory()
  const headers = ['No', 'Code', 'Name', 'Email', 'Created At']
  return (
    <Card>
      <CardBody>
        <Table
          title={'Direct Clickers'}
          headers={headers.map((e) => (
            <th scope="col" key={`thead-${Math.random()}`}>
              {e}
            </th>
          ))}
          rows={data.map((member, index) => (
            <tr>
              <td>{index + 1}</td>
              <td
                onClick={() => {
                  router.push('/member-detail/' + member.code)
                }}
                style={{ textDecoration: 'underline', color: 'blue' }}
              >
                {member.code}
              </td>
              <td>{member.name}</td>
              <th>{member.email}</th>
              <td>{dayjs(member.created_at).format('DD/MM/YYYY')}</td>
            </tr>
          ))}
        />
      </CardBody>
    </Card>
  )
}

export default DirectMembers
