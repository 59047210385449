import React from 'react'
import { getAll } from 'services/purchaseService'

const initialState = { purchases: [], loading: false, total: 0 }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PURCHASE':
      return {
        ...state,
        purchases: action.payload.data.purchases,
        total: action.payload.data.total,
      }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const usePurchase = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchPurchase = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_PURCHASE', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return {
    fetchPurchase,
    state: state,
  }
}

export default usePurchase
