import React from 'react'
import { getAll, getChildAll } from 'services/adsFinishService'

const initialState = { logs: [], loading: false, childLogs: [] }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ADS_FINISH':
      return {
        ...state,
        logs: action.payload.data,
      }
    case 'SET_ADS_CHILD_FINISH':
      return {
        ...state,
        childLogs: action.payload.data,
      }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useAdsFinish = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchAdsFinishLogs = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_ADS_FINISH', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
  const fetchAdsFinishDetailLogs = async (code, date) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getChildAll(code, date)
    dispatch({ type: 'SET_ADS_CHILD_FINISH', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return {
    fetchAdsFinishLogs,
    fetchAdsFinishDetailLogs,
    state: state,
  }
}

export default useAdsFinish
