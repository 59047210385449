import React from 'react'
import List from './list'
import FilterBox from './filter'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import useTransfer from 'hooks/useTransfer'

function Transfer() {
  const router = useHistory()
  const query = queryString.parse(router.location.search)
  let url = ''
  if (query.page) url += `?page=${query.page}`
  if (query.limit) url += `&limit=${query.limit}`
  if (query.start_date && query.end_date)
    url += `&start_date=${query.start_date}&end_date=${query.end_date}`
  if (query.search) url += `&search=${query.search}`

  const headers = [
    'No',
    'From Member',
    'To Member',
    'Amount',
    'Note',
    'Created At',
  ]

  const { state, fetchTransfer } = useTransfer()

  React.useEffect(() => {
    fetchTransfer(url)
  }, [url])

  return (
    <>
      <List
        headers={headers}
        total={state.total}
        data={state.transfers}
        totalAmount={state.totalAmount}
        title="Transfer"
        loading={state.loading}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={<></>}
      />
    </>
  )
}

export default Transfer
