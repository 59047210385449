import Home from 'pages/home'
import Login from 'pages/Login'
import NewAdmin from 'pages/admin/new'
import Admin from 'pages/admin/list'
import NewMember from 'pages/member/new'
import MemberDetail from 'pages/member/detail'
import Member from 'pages/member/list'
import NewPlan from 'pages/plan/new'
import Plan from 'pages/plan/list'
import PlanSetting from 'pages/plan/setting'
import PaymentMethod from 'pages/paymentMethod/list'
import NewPaymentMethod from 'pages/paymentMethod/new'
import Deposit from 'pages/deposit/list'
import WithdrawAllowance from 'pages/withdrawAllowance/list'
import NewWithdrawAllowance from 'pages/withdrawAllowance/new'
import Withdraw from 'pages/withdraw/list'
import Purchase from 'pages/purchase/list'
import Transfer from 'pages/transfer/list'
import AdsFinishLog from 'pages/logs/adsFinish'
import PaymentLog from 'pages/logs/paymentLog'

import AdsFinishLogDetail from 'pages/logs/adsFinish/detail'
import Wallet from 'pages/wallet/list'
import History from 'pages/wallet/history'

const routes = [
  {
    path: '/login',
    name: 'login',
    icon: 'fas fa-home text-primary',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '',
    name: 'Home',
    icon: 'fas fa-home text-cyan',
    component: Home,
    layout: '/',
  },
  {
    collapse: true,
    name: 'Admin',
    icon: 'ni ni-single-02 text-danger',
    state: 'adminCollapse',
    views: [
      {
        path: 'admin-create',
        name: 'Admin - Create',
        miniName: 'OQ',
        component: NewAdmin,
        layout: '/',
      },
      {
        path: 'admin-query',
        name: 'Admin - List',
        miniName: 'OQ',
        component: Admin,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'Clickers',
    icon: '	ni ni-circle-08 text-yellow',
    state: 'memberCollapse',
    views: [
      {
        path: 'member-create',
        name: 'Clickers - Create',
        miniName: 'OQ',
        component: NewMember,
        layout: '/',
      },
      {
        path: 'member-query',
        name: 'Clickers - List',
        miniName: 'OQ',
        component: Member,
        layout: '/',
      },
      {
        path: 'member-detail/:id',
        name: 'Clickers - Detail',
        miniName: 'OQ',
        component: MemberDetail,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Plan',
    icon: '	ni ni-align-left-2 text-warning',
    state: 'planCollapse',
    views: [
      {
        path: 'plan-create',
        name: 'Plan - Create',
        miniName: 'OQ',
        component: NewPlan,
        layout: '/',
      },
      {
        path: 'plan-query',
        name: 'Plan - List',
        miniName: 'OQ',
        component: Plan,
        layout: '/',
      },
      {
        path: 'plan-setting/:id',
        name: 'Plan - setting',
        miniName: 'OQ',
        component: PlanSetting,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Payment Method',
    icon: '	ni ni-money-coins text-danger',
    state: 'paymentMethodCollapse',
    views: [
      {
        path: 'Payment-method-create',
        name: 'Payment Method - Create',
        miniName: 'OQ',
        component: NewPaymentMethod,
        layout: '/',
      },
      {
        path: 'payment-method-query',
        name: 'Payment Method - List',
        miniName: 'OQ',
        component: PaymentMethod,
        layout: '/',
      },
    ],
  },
  {
    path: 'deposit-query',
    name: 'Deposits',
    icon: 'ni ni-credit-card text-green',
    component: Deposit,
    layout: '/',
  },
  {
    path: 'withdraw-query',
    name: 'Withdraws',
    icon: 'ni ni-fat-delete text-danger ',
    component: Withdraw,
    layout: '/',
  },
  {
    path: 'purchase-query',
    name: 'Purchases',
    icon: 'ni ni-fat-add text-warning',
    component: Purchase,
    layout: '/',
  },
  {
    path: 'transfer-query',
    name: 'Transfers',
    icon: 'ni ni-curved-next text-green',
    component: Transfer,
    layout: '/',
  },
  {
    path: 'wallet-query',
    name: 'Wallets',
    icon: 'fa fa-file-word text-green',
    component: Wallet,
    layout: '/',
  },
  {
    path: 'history-query/:id',
    name: 'Wallets',
    icon: 'fa fa-file-word text-green',
    component: History,
    layout: '/',
    invisible: true,
  },
  {
    collapse: true,
    name: 'Setting',
    icon: '	ni ni-settings-gear-65 text-black',
    state: 'settingCollapse',
    views: [
      {
        path: 'Withdraw-allowance-create',
        name: 'Withdraw Allowance - Create',
        miniName: 'OQ',
        component: NewWithdrawAllowance,
        layout: '/',
        invisible: true,
      },
      {
        path: 'withdraw-allowance-query',
        name: 'Withdraw Allowance - List',
        miniName: 'OQ',
        component: WithdrawAllowance,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'Report',
    icon: '	fa fa-file-word text-green',
    state: 'logCollapse',
    views: [
      {
        path: 'ads-finish-logs',
        name: 'Ads Finsh - Report',
        miniName: 'AFL',
        component: AdsFinishLog,
        layout: '/',
      },
      {
        path: 'payment-logs',
        name: 'Payment - Report',
        miniName: 'AFL',
        component: PaymentLog,
        layout: '/',
      },
    ],
  },

  // {
  //   collapse: true,
  //   name: 'Plan Level',
  //   icon: '	fa fa-file-word text-green',
  //   state: 'planLevelCollapse',
  //   views: [
  //     {
  //       path: 'planlevel-create',
  //       name: 'Plan Level - Create',
  //       miniName: 'OQ',
  //       component: NewPlanLevel,
  //       layout: '/',
  //     },
  //     {
  //       path: 'planlevel-query',
  //       name: 'Plan Level - List',
  //       miniName: 'OQ',
  //       component: PlanLevel,
  //       layout: '/',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Plan duration',
  //   icon: '	fa fa-file-word text-green',
  //   state: 'planDurationCollapse',
  //   views: [
  //     {
  //       path: 'plan-duration-create',
  //       name: 'Plan duration - Create',
  //       miniName: 'OQ',
  //       component: NewPlanDuration,
  //       layout: '/',
  //     },
  //     {
  //       path: 'plan-duration-query',
  //       name: 'Plan duration - List',
  //       miniName: 'OQ',
  //       component: PlanDuration,
  //       layout: '/',
  //     },
  //   ],
  // },
]

const getAllRoutes = () => {
  const allRoutes = []
  routes.forEach((e) => {
    if (e.collapse) {
      e.views.map((v) => allRoutes.push(v))
    } else allRoutes.push(e)
  })
  return allRoutes
}
const allRoutes = getAllRoutes()

export { routes, allRoutes }
