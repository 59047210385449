import React from 'react'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import usePurchase from 'hooks/usePurchase'
import queryString from 'query-string'
import { useHistory } from 'react-router'

function Purchase() {
  const router = useHistory()
  const headers = ['No', 'Member', 'Amount', 'Plan', 'Created At']

  const query = queryString.parse(router.location.search)
  let url = ''
  if (query.page) url += `?page=${query.page}`
  if (query.limit) url += `&limit=${query.limit}`

  const { state, fetchPurchase } = usePurchase()

  React.useEffect(() => {
    fetchPurchase(url)
  }, [url])

  return (
    <>
      <List
        headers={headers}
        total={state.total}
        data={state.purchases}
        title="Purchase"
        loading={state.loading}
        filter={<FilterBox />}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                fetchPurchase(url)
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
    </>
  )
}

export default Purchase
