import { getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll(query) {
  try {
    let response = await getData(`purchase-admin${query}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
