import React from 'react'
import Table from 'components/Table'
import Button from 'components/Button'
import dayjs from 'dayjs'
import { percentageCalculator } from 'utli'
import { useHistory, useLocation } from 'react-router'
function List({
  headers,
  data,
  title,
  buttons,
  filter,
  loading,
  total,
  viewDetail,
}) {
  const router = useHistory()
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      filter={filter}
      buttons={buttons}
      total={total}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.member.code}</td>
          <td>{e.member.name}</td>
          <td>{e.member.plan.name}</td>
          <td>
            {e.member.plan.activityIncome}% of {e.member.plan.price}
          </td>
          <td>
            {' '}
            {percentageCalculator(
              e.member.plan.price,
              e.member.plan.activityIncome
            )}
          </td>
          <td>{dayjs(e.date).format('DD/MM/YYYY')}</td>
          <td>
            <Button
              size="sm"
              onClick={() =>
                viewDetail(e.member.code, dayjs(e.date).format('YYYY-MM-DD'))
              }
            >
              Detail
            </Button>
          </td>
        </tr>
      ))}
    />
  )
}

export default List
